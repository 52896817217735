import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import Layout from "./components/Layout";
import Presale from "./pages/Presale";
import toast from "react-hot-toast";

function App() {
  const toastError = (err) => {
    console.log(err);
    try {
      let message = err.message;
      if (err.data && err.data.message) message = err.data.message;
      toast.error(message.split(":")[message.split(":").length - 1].trim(), {
        style: {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "999rem",
          background: "#222222",
          border: "1px solid #282828",
          color: "#fff",
        },
      });
    } catch (err) {}
  };

  const location = useLocation();
  return (
    <Layout>
      <Routes location={location}>
        <Route path="/" element={<Presale />} />
        <Route path="*" element={<Navigate replace to="/" />} />
      </Routes>
    </Layout>
  );
}

export default App;
