import classes from "./index.module.css";
import { Toaster } from "react-hot-toast";
import Web3ModalButton from "./Web3ModalButton";

const Layout = ({ children }) => {
  return (
    <div className="flex">
      <Toaster position="bottom-center" />
      <div className={`${classes.main} relative w-full h-screen`}>
        <div className="w-full flex justify-center items-center">
          <div className="w-11/12 md:w-3/4 relative flex flex-col justify-center items-center z-10">
            <Web3ModalButton
              className={` hidden lg:flex  absolute bg-transparent border-2 border-pinky brightness-100 hover:bg-pinky drop-shadow-xl rounded-lg text-white transition-all font-medium py-2 z-10 px-4 h-12 right-0 top-10 justify-center items-center cursor-pointer gap-2`}
            />
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Layout;
