import { useState, useEffect, useMemo } from "react";
import { toast } from "react-hot-toast";
import { utils } from "ethers";
import classes from "./Presale.module.css";
import { useEtherBalance, useEthers } from "@usedapp/core";

import Web3ModalButton from "../components/Layout/Web3ModalButton";
import useGetInfo from "../hooks/read/useGetInfo";
import useRemainingAlloc from "../hooks/read/useRemainingAlloc";
import useAllocation from "../hooks/read/useAllocation";
import useContribute from "../hooks/write/useContribute";
import useClaim from "../hooks/write/useClaim";

const toastOptions = {
  style: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "999rem",
    background: "#222222",
    border: "1px solid #282828",
    color: "#fff",
  },
};

const Presale = () => {
  const { account } = useEthers();
  const _ethBalance = useEtherBalance(account);
  const remainingAlloc = useRemainingAlloc(account);
  const allocation = useAllocation(account);
  const info = useGetInfo();
  const contribute = useContribute();
  const claim = useClaim();

  const [inputAmount, setInputAmount] = useState();

  const ethBalance = useMemo(
    () =>
      _ethBalance ? (+utils.formatEther(_ethBalance)).toFixed(3) - 0.001 : 0,
    [_ethBalance]
  );

  useEffect(() => {
    if (
      contribute.state.status == "Exception" ||
      contribute.state.status == "Fail"
    ) {
      toast.error(
        contribute.state.errorMessage
          .split(":")
          [contribute.state.errorMessage.split(":").length - 1].trim(),
        toastOptions
      );
    } else if (contribute.state.status == "Success") {
      toast.success("Successfully contributed!", toastOptions);
    }
  }, [contribute.state]);

  useEffect(() => {
    if (claim.state.status == "Exception" || claim.state.status == "Fail") {
      toast.error(
        claim.state.errorMessage
          .split(":")
          [claim.state.errorMessage.split(":").length - 1].trim(),
        toastOptions
      );
    } else if (claim.state.status == "Success") {
      toast.success("Successfully claimed!", toastOptions);
    }
  }, [claim.state]);

  const handleMax = () => {
    setInputAmount(
      ethBalance >= remainingAlloc
        ? utils.parseEther(info[3])
        : ethBalance < remainingAlloc
        ? `${ethBalance}`
        : remainingAlloc
    );
  };

  const handleClick = () => {
    claim.send();
    contribute.send({ value: utils.parseEther(inputAmount) });
  };

  return (
    <div className="w-full h-screen relative flex flex-col justify-center items-center -z-10 gap-8 mt-8 lg:mt-0">
      <h1
        className={`${classes.heading} font-bold uppercase text-4xl md:text-6xl text-white relative pb-4 mb-4`}
      >
        PULSEDRIP PRESALE
      </h1>
      <div className="w-full flex justify-center gap-8 bg-black bg-opacity-50 drop-shadow-xl rounded-2xl md:w-4/5 2xl:w-1/2 p-4">
        <div
          className={`relative bg-black bg-opacity-60 rounded-2xl text-white drop-shadow-xl text-center flex flex-col  w-full p-6`}
        >
          <div className="flex justify-between">
            <div className="opacity-50">Claim</div>
            {/* <div onClick={handleMax} className="cursor-pointer">
              <span className="opacity-50">Max</span>
              <span className="text-pinky ml-2 cursor-pointer">
                {ethBalance ? ethBalance : 0}
              </span>
            </div> */}
          </div>
          {/* <div className="flex items-center justify-center border-b border-pinky">
            <input
              placeholder="Amount of PLS"
              className=" h-full z-20 w-full flex-1 outline-none  bg-transparent py-3 opacity-100 disabled:cursor-not-allowed"
              value={inputAmount}
              onChange={(e) => setInputAmount(e.target.value)}
            />
            <div>PLS</div>
          </div> */}
          <div className="flex flex-col w-full text-sm py-6">
            <div className="w-full flex justify-between">
              <p className="opacity-50">Status</p>
              {!info[0] && !info[1] && <p>Awaiting</p>}
              {info[0] && !info[1] && <p>Private</p>}
              {info[0] && info[1] && <p>Public</p>}
            </div>
            <div className="w-full flex justify-between">
              <p className="opacity-50">Price</p>
              <p>{utils.formatEther(info[10])} PLS / xDRIP</p>
            </div>
            {/* <div className="w-full flex justify-between">
              <p className="opacity-50">Minimum contribution</p>
              <p>{utils.formatEther(info[2])} PLS</p>
            </div>
            <div className="w-full flex justify-between">
              <p className="opacity-50">Maximum contribution</p>
              <p>{utils.formatEther(info[3])} PLS</p>
            </div>
            <div className="w-full flex justify-between">
              <p className="opacity-50">Remaining tokens</p>
              {!info[0] && !info[0] && (
                <p>{utils.formatEther(info[6])} PDRIP</p>
              )}
              {info[0] && !info[1] && (
                <p>{utils.formatEther(info[4].sub(info[7]))} PDRIP</p>
              )}
              {info[0] && info[1] && (
                <p>{utils.formatEther(info[5].sub(info[8]))} PDRIP</p>
              )}
              <p>
                {(parseFloat(utils.formatEther(info[6])) - info[9]).toFixed(3)}{" "}
                xDRIP
              </p>
            </div> */}
            {/* <div className="w-full flex justify-between">
              <p className="opacity-50">Remaining contribution</p>
              <p>{remainingAlloc.toFixed(1)} PLS</p>
            </div> */}
            <div className="w-full flex justify-between">
              <p className="opacity-50">Your reserved tokens</p>
              <p>{allocation} xDRIP</p>
            </div>
          </div>
          <div className="flex justify-center items-center w-full">
            {!account && (
              <Web3ModalButton
                className={`bg-pinky brightness-100 hover:brightness-75 drop-shadow-xl rounded-lg text-white focus:brightness-[0.65] transition-all font-medium w-3/4 py-2 relative z-10`}
              />
            )}
            {account && (
              <button
                className={`bg-pinky brightness-100 hover:brightness-75 drop-shadow-xl rounded-lg text-white focus:brightness-[0.65] transition-all font-medium w-3/4 py-2 relative z-10`}
                onClick={handleClick}
              >
                Claim
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Presale;
