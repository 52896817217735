import { BigNumber, utils } from 'ethers'
import { useCall } from '@usedapp/core';
import { Contract } from '@ethersproject/contracts'
import IPresale from '../../contracts/presale.json'
import { PRESALE_ADDRESS } from '../../config';

const useGetInfo = () => {
  const { value, error } =
    useCall(
        PRESALE_ADDRESS && {
          contract: new Contract(PRESALE_ADDRESS, new utils.Interface(IPresale)),
          method: 'getInfo',
          args: [],
        }
    ) ?? { value : [false, false, '0', '0', BigNumber.from('0'), BigNumber.from('0'), BigNumber.from('0'), BigNumber.from('0'), BigNumber.from('0'), BigNumber.from('0'), BigNumber.from('0')] }
  if(error) {
    console.error(error.message)
    return [false, false, '0', '0', BigNumber.from('0'), BigNumber.from('0'), BigNumber.from('0'), BigNumber.from('0'), BigNumber.from('0'), BigNumber.from('0'), BigNumber.from('0')]
  }
  return value
}

export default useGetInfo