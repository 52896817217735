import { utils } from 'ethers'
import { useCall } from '@usedapp/core';
import { Contract } from '@ethersproject/contracts'
import IPresale from '../../contracts/presale.json'
import { PRESALE_ADDRESS } from '../../config';

const useRemainingAlloc = (address) => {
  const { value, error } =
    useCall(
      address &&
      PRESALE_ADDRESS && {
          contract: new Contract(PRESALE_ADDRESS, new utils.Interface(IPresale)),
          method: 'remainingAlloc',
          args: [address],
        }
    ) ?? { value : [0] }
  if(error) {
    console.error(error.message)
    return undefined
  }
  return parseFloat(utils.formatEther(value?.[0]))
}

export default useRemainingAlloc