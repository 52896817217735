import { utils } from 'ethers'
import { useContractFunction  } from '@usedapp/core';
import { Contract } from '@ethersproject/contracts'
import IPresale from '../../contracts/presale.json'
import { PRESALE_ADDRESS } from '../../config';

const useContribute = () => {
  const contract = new Contract(PRESALE_ADDRESS, new utils.Interface(IPresale))
  const { state, send } = useContractFunction(contract, 'contribute', { transactionName: 'Contribute!' })

  return { state, send }
}

export default useContribute