import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import { DAppProvider, DEFAULT_SUPPORTED_CHAINS } from "@usedapp/core";
import { getDefaultProvider } from "ethers";
import { PulseChain } from "./PulseChain";

const config = {
  readOnlyChainId: PulseChain.chainId,
  readOnlyUrls: {
    [PulseChain.chainId]: getDefaultProvider(PulseChain.rpcUrl),
  },
  pollingInterval: 10000,
  autoConnect: true,
  networks: [...DEFAULT_SUPPORTED_CHAINS, PulseChain],
};

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <DAppProvider config={config}>
        <App />
      </DAppProvider>
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);
